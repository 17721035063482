import React from "react";
import {
  Home,
  Package,
  Send,
  ChevronDown,
  ChevronUp,
  Grid,
  Settings,
  Users,
} from "react-feather";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { addLog } from "../../services/logs";
import checkRole from "../../helpers/check_role";
import moment from "moment";

export function SidebarNav({ info, router: { location } }) {
  const navigate = useNavigate();
  const [showSettings, setShowSettings] = React.useState(false);
  const [showExport, setShowExport] = React.useState(false);
  const [showImport, setShowImport] = React.useState(false);
  const [showManagement, setShowManagement] = React.useState(false);
  const [showAdmin, setShowAdmin] = React.useState(false);

  // const adminRole = info.role.includes("ROLE_ADMIN");
  let pathname = location.pathname;

  const logOut = async () => {
    const data = {
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      action: "Logout",
      action_details: "Logged out",
    };

    var jsonData = JSON.stringify(data);

    await addLog(jsonData);

    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleDropdown = (e) => {
    setShowExport(false);
    setShowSettings(false);
    setShowAdmin(false);
    setShowManagement(false);

    switch (e) {
      case "settings":
        setShowSettings(true);
        break;
      case "export":
        setShowExport(true);
        break;
      case "admin":
        setShowAdmin(true);
        break;
      case "management":
        setShowManagement(true);
      default:
        break;
    }
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner">
        <div id="sidebar-menu" className="sidebar-menu">
          <div style={{ marginBottom: 20, color: "rgba(35, 43, 53)" }}>
            Overview
          </div>
          <ul>
            <li>
              <Link to="/" style={{ textDecorationLine: "none" }}>
                <Home />{" "}
                <span
                  style={{
                    fontSize: "SeagoeUI",
                    marginLeft: 20,
                    cursor: "pointer",
                    fontFamily: pathname === "/" ? "SeagoeUIBold" : "SeagoeUI",
                  }}
                >
                  Dashboard
                </span>
              </Link>
            </li>
            {checkRole(info.role, "ROLE_SUPER_USER") && (
              <li>
                <a onClick={() => setShowAdmin(!showAdmin)}>
                  <Grid />{" "}
                  <span
                    style={{
                      fontSize: "SeagoeUI",
                      marginLeft: 20,
                      cursor: "pointer",
                    }}
                  >
                    Admin
                  </span>
                  {showAdmin ? (
                    <ChevronUp className="chevron" />
                  ) : (
                    <ChevronDown className="chevron" />
                  )}
                </a>

                <ul
                  className={
                    showAdmin ? "sidebar-dropdown show" : "sidebar-dropdown  "
                  }
                >
                  <li>
                    <Link
                      to="/user"
                      style={{
                        textDecorationLine: "none",
                        paddingLeft: 60,
                        fontFamily: pathname.includes("/user")
                          ? "SeagoeUIBold"
                          : "SeagoeUI",
                      }}
                      onClick={() => handleDropdown("admin")}
                    >
                      User
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {
              <li>
                <a onClick={() => setShowExport(!showExport)}>
                  <Send />{" "}
                  <span style={{ fontSize: "SeagoeUI", marginLeft: 20 }}>
                    Export
                  </span>
                  {showExport ? (
                    <ChevronUp className="chevron" />
                  ) : (
                    <ChevronDown className="chevron" />
                  )}
                </a>

                <ul
                  className={
                    showExport ? "sidebar-dropdown show" : "sidebar-dropdown"
                  }
                >
                  {
                    <li>
                      <Link
                        to="/create_export"
                        style={{
                          textDecorationLine: "none",
                          paddingLeft: 60,
                          fontFamily:
                            pathname === "/create_export"
                              ? "SeagoeUIBold"
                              : "SeagoeUI",
                        }}
                        onClick={() => handleDropdown("export")}
                      >
                        Create Declaration
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {checkRole(info.role, "ROLE_SUPER_USER") && (
              <li>
                <a onClick={() => setShowManagement(!showManagement)}>
                  <Users />{" "}
                  <span style={{ fontSize: "SeagoeUI", marginLeft: 20 }}>
                    Management
                  </span>
                  {showManagement ? (
                    <ChevronUp className="chevron" />
                  ) : (
                    <ChevronDown className="chevron" />
                  )}
                </a>

                <ul
                  className={
                    showManagement
                      ? "sidebar-dropdown show"
                      : "sidebar-dropdown"
                  }
                >
                  <li>
                    <Link
                      to="/internal_kpi"
                      style={{
                        textDecorationLine: "none",
                        paddingLeft: 60,
                        fontFamily:
                          pathname === "/internal_kpi"
                            ? "SeagoeUIBold"
                            : "SeagoeUI",
                      }}
                      onClick={() => handleDropdown("management")}
                    >
                      Internal KPI
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/statistics_reports"
                      style={{
                        textDecorationLine: "none",
                        paddingLeft: 60,
                        fontFamily:
                          pathname === "/statistics_reports"
                            ? "SeagoeUIBold"
                            : "SeagoeUI",
                      }}
                      onClick={() => handleDropdown("management")}
                    >
                      Statistics & Reports
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {checkRole(info.role, "ROLE_ADMIN") && (
              <li>
                <a onClick={() => setShowSettings(!showSettings)}>
                  <Settings />{" "}
                  <span style={{ fontSize: "SeagoeUI", marginLeft: 20 }}>
                    Settings
                  </span>
                  {showSettings ? (
                    <ChevronUp className="chevron" />
                  ) : (
                    <ChevronDown className="chevron" />
                  )}
                </a>

                <ul
                  className={
                    showSettings ? "sidebar-dropdown show" : "sidebar-dropdown"
                  }
                >
                  {checkRole(info.role, "ROLE_ADMIN") && (
                    <li>
                      <Link
                        to="/logs"
                        style={{
                          textDecorationLine: "none",
                          paddingLeft: 60,
                          fontFamily:
                            pathname === "/logs" ? "SeagoeUIBold" : "SeagoeUI",
                        }}
                        onClick={() => handleDropdown("settings")}
                      >
                        Logs
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
          </ul>
        </div>
        <div className="overview-sidebar">
          <div className="avatar-sidebar">
            <div className="avatar-top-right">
              <p className="role-sidebar">
                {info.role[0]
                  .replace("ROLE_", "")
                  .replace(/_/g, " ")
                  .toLowerCase()
                  .replace(/\b\w/g, (c) => c.toUpperCase())}
              </p>
            </div>
            <img
              src={info.photo}
              alt={""}
              width={44}
              height={44}
              style={{ borderRadius: "50%" }}
            />
          </div>
          <p className="name-sidebar">{info.name}</p>
          <p className="email-sidebar">{info.email}</p>
          <div className="button-sidebar" onClick={logOut}>
            <p
              style={{
                color: "#fff",
                fontFamily: "SeagoeUIBold",
                fontSize: "0.8rem",
              }}
            >
              Log out
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter(SidebarNav);
